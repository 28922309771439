<template>
    <div class="bg-white">
        <EnvBanner />
        <div class="h-screen relative overflow-auto">
            <Circles />
            <div
                class="relative flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <LogoImage class="h-10" />
                <div class="mt-12 sm:mx-auto sm:w-full sm:max-w-[520px]">
                    <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <slot />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script setup>
</script>
